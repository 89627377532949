import React, { useEffect, useContext, useState } from "react";
import Slider from "react-slick";
import { SettingsContext, ShopContext } from "../App";
import Api from "./api/api";
import "../css/Slider.css";

function Responsive() {
	const { lang } = useContext(SettingsContext);
	const { brand } = useContext(ShopContext);
	const [slides, setSlides] = useState(false);
	const [sliderSettings, setSliderSettings] = useState(false);

	useEffect(() => {
		loadSlides();
	}, []);

	useEffect(() => {
		if (slides) {
			if (slides.length === 1) {
				setSliderSettings({
					dots: false,
					infinite: true,
					speed: 500,
					slidesToShow: 1,
					slidesToScroll: 1,
					initialSlide: 0,
					autoplay: true,
					arrows: false,
					responsive: [],
				});
			}
			if (slides.length === 2) {
				setSliderSettings({
					dots: false,
					infinite: true,
					speed: 500,
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 0,
					autoplay: true,
					arrows: false,
					responsive: [],
				});
			}
			if (slides.length === 3) {
				setSliderSettings({
					dots: false,
					infinite: true,
					speed: 500,
					slidesToShow: 2,
					slidesToScroll: 1,
					initialSlide: 0,
					autoplay: true,
					arrows: false,
					responsive: [],
				});
			}
			if (slides.length >= 4) {
				setSliderSettings({
					dots: false,
					infinite: true,
					speed: 500,
					slidesToShow: 4,
					slidesToScroll: 1,
					initialSlide: 0,
					autoplay: true,
					arrows: false,
					responsive: [
						{
							breakpoint: 1024,
							settings: {
								slidesToShow: 3,
								slidesToScroll: 1,
								infinite: true,
							},
						},
						{
							breakpoint: 600,
							settings: {
								slidesToShow: 2,
								slidesToScroll: 1,
							},
						},
					],
				});
			}
		}
	}, [slides]);

	const loadSlides = async () => {
		console.log("loadSlides");
		let request = {
			lang: lang,
			orderby: "position",
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
				{
					name: "selforder",
					value: 1,
					compare: "equal",
				},
			],
		};
		if (brand) {
			//request.src.push({ name: "brand_id", value: brand.id, compare: "equal" });
		}
		//console.log(request);
		const response = await Api.post("/slides/list/", request);
		//console.log(response);
		setSlides(response.rows);
	};

	return (
		<>
			{sliderSettings && (
				<div className="slider-container">
					<Slider {...sliderSettings}>
						{slides.map((slide, i) => (
							<div key={i}>
								<a
									style={{
										backgroundImage: "url(" + slide.img + ")",
									}}
									className="slide"
									href={slide.url}
									target="_blank"
								></a>
							</div>
						))}
					</Slider>
				</div>
			)}
		</>
	);
}

export default Responsive;
