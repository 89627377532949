import { useContext, useEffect, useState } from "react";
import Api from "../api/api";
import { SettingsContext, CartContext, ShopContext, UserContext, TableContext } from "../../App";
import TempUserPopup from "../TempUserPopup";

function Nexi({ closeCart, checkoutData }) {
	const { settings, setLoading } = useContext(SettingsContext);
	const { shop } = useContext(ShopContext);
	const { cart } = useContext(CartContext);
	const { table } = useContext(TableContext);
	const { user } = useContext(UserContext);
	const [paymentUrl, setPaymentUrl] = useState(false);
	const [tempUserPopupVisible, setTempUserPopupVisible] = useState(false);

	useEffect(() => {
		window.addEventListener("message", checkPayment, false);
		return () => window.removeEventListener("message", checkPayment);
	}, []);

	const checkPayment = (event) => {
		console.log("New message recived");
		console.log(event.data);
		if (event.data.status == "undo") setPaymentUrl(false);
		if (event.data.status == "failed") setPaymentUrl(false);
		if (event.data.status == "success") closeCart(event.data);
	};

	const handleClick = () => {
		if (user) pay();
		else setTempUserPopupVisible(true);
	};

	const pay = async (tempUser = false) => {
		setLoading(true);
		const request = {
			cart_id: table.id,
			total: cart.total,
			shop_id: cart.shop_id,
			origin: "selfordering",
			name: tempUser ? tempUser.name : user.name,
			surname: tempUser ? tempUser.surname : user.surname,
			email: tempUser ? tempUser.email : user.email,
			checkout_data: checkoutData,
		};
		console.log(request);
		const response = await Api.post(
			"/plugins/xpay/init_payment.php?m_id=" + settings.mandant.id,
			request
		);
		console.log(response);
		if (response.success == 0) {
			setLoading(false);
			alert(response.error);
			return;
		}
		setPaymentUrl(true);
		var mapForm = document.createElement("form");
		mapForm.target = "nexi-iframe";
		mapForm.method = "POST";
		mapForm.action = response.url;

		for (const [key, value] of Object.entries(response.data)) {
			var mapInput = document.createElement("input");
			mapInput.type = "text";
			mapInput.name = key;
			mapInput.value = value;
			mapForm.appendChild(mapInput);
		}
		document.body.appendChild(mapForm);
		mapForm.submit();
		mapForm.remove();
	};

	return (
		<>
			<button onClick={handleClick}>{shop.payment_methods[11].label}</button>
			{paymentUrl && (
				<div className="nexi-popup">
					<iframe
						src={paymentUrl}
						name="nexi-iframe"
						onmessage={(event) => console.log(event)}
						onLoad={(e) => {
							console.log(e);
							setLoading(false);
						}}
					></iframe>
				</div>
			)}
			{tempUserPopupVisible && (
				<TempUserPopup
					onCancel={() => setTempUserPopupVisible(false)}
					onSubmit={(data) => {
						setTempUserPopupVisible(false);
						pay(data);
					}}
				/>
			)}
		</>
	);
}

export default Nexi;
