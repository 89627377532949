import { useContext, useEffect } from "react";
import { CategoriesContext, SettingsContext } from "../App";
import ProductsList from "./ProductsList";
import CategoriesTags from "./CategoriesTags.js";

function ProductsOnePage() {
	const { settings, category } = useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);

	useEffect(() => {
		scrollProducts();
	}, [category]);

	const scrollProducts = async () => {
		if (settings.selfordering.categories_menu_type != 3) {
			console.log("scrollProducts");
			if (category) {
				const element = document.getElementById("category-" + category.id);
				if (element) {
					// 👇 Will scroll smoothly to the top of the next section
					element.scrollIntoView({ behavior: "smooth" });
				}
			}
		}
	};

	return (
		<>
			{categories.map((course, i) => (
				<div key={i}>
					{course.name && <h3 className="mb-3">{course.name}</h3>}
					{course.categories.map((category, n) => (
						<div key={n} id={"category-" + category.id} className="category-box">
							<div className="category-title-container">
								<h3 className="category-title">{category.name}</h3>
								<CategoriesTags category={category} />
							</div>
							{category.description != "" && (
								<p className="mb-3">{category.description}</p>
							)}
							<ProductsList products={category.products} />
						</div>
					))}
				</div>
			))}
		</>
	);
}

export default ProductsOnePage;
