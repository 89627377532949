import { useContext } from "react";
import { CartContext, SettingsContext, ShopContext, TableContext, UserContext } from "../../App";
import api from "../api/api";

function Satispay() {
	const { settings, accessType, userID, monoOrder, setLoading } = useContext(SettingsContext);
	const { shop, brand } = useContext(ShopContext);
	const { table } = useContext(TableContext);
	const { cart } = useContext(CartContext);
	const { user } = useContext(UserContext);
	const demo =
		window.location.hostname === "localhost" || window.location.hostname.includes(".localhost")
			? 1
			: 0;

	const pay = async () => {
		setLoading(true);
		const response = await api.get(
			"/plugins/satispay2/init_payment.php?mandant_id=" +
				settings.mandant.id +
				"&id=" +
				table.id +
				"&amount=" +
				cart.total +
				"&customer_id=" +
				(user ? user.id : "") +
				"&shop_id=" +
				cart.shop_id +
				"&table_id=" +
				table.id +
				"&user_id=" +
				userID +
				"&mono_order=" +
				monoOrder +
				"&access_type=" +
				accessType +
				"&brand_id=" +
				(brand ? brand.id : "") +
				"&origin=selfordering&demo=" +
				demo
		);
		console.log(response);
		if (response.success == 0) {
			setLoading(false);
			alert(response.error);
		} else {
			if (response.data.callback_url) window.open(response.data.callback_url);
			window.location = response.data.redirect_url;
		}
	};

	return (
		<button
			className="button"
			onClick={pay}
			style={{
				backgroundColor: settings.selfordering.color1,
				color: settings.selfordering.color2,
			}}
		>
			{shop.payment_methods[8].label}
		</button>
	);
}

export default Satispay;
