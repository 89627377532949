import { useContext, useEffect, useState } from "react";
import { SettingsContext, ShopContext } from "../App";
import LangSelector from "./LangSelector";
import Label from "./Label";
import "../css/Pin.css";

function Name() {
	const { settings, open_temp_cart } = useContext(SettingsContext);
	const { shop } = useContext(ShopContext);
	const [inputValue, setInputValue] = useState("");

	const login = async (e) => {
		console.log("login");
		if (e != undefined) e.preventDefault();
		open_temp_cart(shop.id, inputValue);
	};

	return (
		<div id="pin">
			<div className="logo">
				<img src={settings.selfordering.logo_home} alt="Logo" />
			</div>
			<div
				className="form"
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
			>
				<form className="content" onSubmit={login}>
					<h2>
						<Label number={28} />
					</h2>
					<p>
						<Label number={117} />
					</p>
					<div className="form-line">
						<div
							className="label"
							style={{
								backgroundColor: settings.selfordering.color2,
								color: settings.selfordering.color1,
							}}
						>
							<Label number={33} />
						</div>
						<input
							type="text"
							name="name"
							onChange={(e) => setInputValue(e.target.value)}
							required
						></input>
					</div>
					<button
						style={{
							backgroundColor: settings.selfordering.color3,
							color: settings.selfordering.color4,
						}}
					>
						<Label number={64} />
					</button>
					{settings.selfordering.multilanguage == 1 && (
						<div className="lang-selector-container">
							<LangSelector />
						</div>
					)}
				</form>
			</div>
		</div>
	);
}

export default Name;
