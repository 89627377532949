import { useContext } from "react";
import { SettingsContext } from "../App";

export default function Privacy() {
	const { settings, legal, setLegalPupop, setPagePopup } = useContext(SettingsContext);

	return (
		<>
			{legal &&
				Object.keys(legal).map((id, i) => {
					if (id == "privacy") {
						return (
							<div className="" key={i}>
								{legal[id].label_text}
								{settings?.orders?.privacy_page_id == "" ? (
									<a href="#" onClick={() => setLegalPupop(legal[id])}>
										{legal[id].label_linked}
									</a>
								) : (
									<a
										href="#"
										onClick={() =>
											setPagePopup(settings.orders.privacy_page_id)
										}
									>
										{legal[id].label_linked}
									</a>
								)}
								{legal[id].label_text_after}
							</div>
						);
					}
				})}
		</>
	);
}
