import { useContext } from "react";
import { CartContext } from "../App";

function Groups({ product }) {
	const { cart } = useContext(CartContext);

	return (
		<div className="groups">
			{cart &&
				cart.list_id &&
				product.limit_groups &&
				product.limit_groups.map(
					(limit_group, i) =>
						limit_group.lists.includes(cart.list_id) && (
							<div key={i}>{limit_group.name}</div>
						)
				)}
		</div>
	);
}

export default Groups;
