import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { CategoriesContext, SettingsContext } from "../App";
import "../css/Courses.css";

function Courses() {
	const { settings, setCourse } = useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);

	return (
		<>
			{settings.selfordering.list_orizzontal_categories == 1 ? (
				<div className="courses">
					{categories.map(
						(cr, i) =>
							cr.name && (
								<button
									key={i}
									onClick={() => setCourse(cr)}
									className="course"
									style={{
										backgroundColor: settings.selfordering.color1,
										color: settings.selfordering.color2,
									}}
								>
									{cr.name}
								</button>
							)
					)}
				</div>
			) : (
				<div className="row">
					{categories.map((cr, i) => (
						<div key={i} className="col-6 col-sm-4 col-lg-3">
							<button onClick={() => setCourse(cr)} className="category">
								{cr.img && <div className="img">{<img src={cr.img} />}</div>}
								<p>{cr.name}</p>
							</button>
						</div>
					))}
				</div>
			)}
		</>
	);
}

export default Courses;
