import { useContext, useState } from "react";
import { SettingsContext } from "../App";
import itFlag from "../images/flags/it.png";
import enFlag from "../images/flags/en.png";
import zhFlag from "../images/flags/zh.png";
import frFlag from "../images/flags/fr.png";
import esFlag from "../images/flags/es.png";
import deFlag from "../images/flags/de.png";

export default function LangSelectorHeader() {
	const { settings, lang, setLang } = useContext(SettingsContext);
	const [menuOpen, setMenuOpen] = useState(false);

	if (settings.mandant.multilanguage != 1) return null;

	return (
		<div className="select-languages">
			<button className="opener" onClick={() => setMenuOpen(!menuOpen)}>
				{lang == "it" && (
					<>
						<img src={itFlag} />
						ITA
					</>
				)}
				{lang == "en" && (
					<>
						<img src={enFlag} />
						ENG
					</>
				)}
				{lang == "zh" && (
					<>
						<img src={zhFlag} />
						CIN
					</>
				)}
				{lang == "fr" && (
					<>
						<img src={frFlag} />
						FRA
					</>
				)}
				{lang == "es" && (
					<>
						<img src={esFlag} />
						SPA
					</>
				)}
				{lang == "de" && (
					<>
						<img src={deFlag} />
						TED
					</>
				)}
			</button>
			{menuOpen && (
				<div className="submenu">
					{settings.global.it == 1 && (
						<button
							onClick={() => {
								setLang("it");
								setMenuOpen(false);
							}}
						>
							<img src={itFlag} />
							ITA
						</button>
					)}
					{settings.global.en == 1 && (
						<button
							onClick={() => {
								setLang("en");
								setMenuOpen(false);
							}}
						>
							<img src={enFlag} />
							ENG
						</button>
					)}
					{settings.global.zh == 1 && (
						<button
							onClick={() => {
								setLang("zh");
								setMenuOpen(false);
							}}
						>
							<img src={zhFlag} />
							CIN
						</button>
					)}
					{settings.global.fr == 1 && (
						<button
							onClick={() => {
								setLang("fr");
								setMenuOpen(false);
							}}
						>
							<img src={frFlag} />
							FRA
						</button>
					)}
					{settings.global.es == 1 && (
						<button
							onClick={() => {
								setLang("es");
								setMenuOpen(false);
							}}
						>
							<img src={esFlag} />
							SPA
						</button>
					)}
					{settings.global.de == 1 && (
						<button
							onClick={() => {
								setLang("de");
								setMenuOpen(false);
							}}
						>
							<img src={deFlag} />
							TED
						</button>
					)}
				</div>
			)}
		</div>
	);
}
