import { useContext } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import { SettingsContext, CartContext, ShopContext } from "../../App";

function Paypal({ onPayment }) {
	const { settings, setSettings } = useContext(SettingsContext);
	const { shop, setShop } = useContext(ShopContext);
	const { cart, setCart } = useContext(CartContext);

	return (
		<PayPalButton
			amount={cart.total}
			shippingPreference="NO_SHIPPING"
			currency="EUR"
			options={{
				clientId:
					shop.payment_methods[3].environment == "sandbox"
						? shop.payment_methods[3].sandbox_client_id
						: shop.payment_methods[3].production_client_id,
				currency: "EUR",
				disableFunding: "card",
			}}
			onSuccess={(details, data) => {
				console.log(details);
				console.log(data);
				onPayment(3, details.id);
			}}
		/>
	);
}

export default Paypal;
