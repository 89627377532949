import { useContext } from "react";
import { UserContext } from "../../App";
import Label from "../Label";

function Qr() {
	const { user } = useContext(UserContext);

	return (
		<div className="verticalContainer">
			<div className="title">
				<Label number={75} />
			</div>
			<img
				className="qr"
				src={"https://api.yellgo.cloud/class/barcode.php?f=png&s=qr&sf=8&d=id=" + user.id}
				alt="Qr code"
			/>
		</div>
	);
}

export default Qr;
