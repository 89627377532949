import { useContext, useEffect, useState } from "react";
import { CartContext, SettingsContext } from "../App";
import CartQtySelector from "./CartQtySelector";
import Label from "./Label";

function AddToCartButton({ product, label = false }) {
	const { settings, setSelectedProduct, setProductSheet, handleAddProduct } =
		useContext(SettingsContext);
	const { cart, updateQty } = useContext(CartContext);
	const [cartID, setCartID] = useState(false);
	const [cartQty, setCartQty] = useState(0);

	useEffect(() => {
		if (!cart) return;
		setCartID(false);
		setCartQty(0);
		for (const cartProduct of cart.products) {
			if (cartProduct.product_id == product.id) {
				setCartID(cartProduct.item_id);
				setCartQty(cartProduct.qty);
			}
		}
	}, [cart]);

	const hasVariants2 = () => {
		let response = false;
		for (const variants_category of product.variants_categories) {
			if (variants_category.variants2.length > 0) response = true;
		}
		return response;
	};

	const hasRemovableIngredients = (product) => {
		return product.ingredients.filter((i) => i.removable == 1).length > 0;
	};

	return (
		<>
			{(product.variants_categories.length > 0 || hasRemovableIngredients(product)) && (
				<button
					className="button"
					onClick={() => {
						setSelectedProduct(product);
						setProductSheet(false);
					}}
				>
					{label && <Label number={15} />}
					<i
						className="fas fa-plus"
						style={{
							backgroundColor: settings.selfordering.color1,
							color: settings.selfordering.color2,
						}}
					></i>
				</button>
			)}
			{product.variants_categories.length == 0 &&
				!hasRemovableIngredients(product) &&
				!hasVariants2() &&
				(settings.selfordering.show_inline_qty == 0 ||
					settings.selfordering.enable_flow == 1) && (
					<button
						className="button"
						onClick={() =>
							handleAddProduct({
								...product,
								qty: 1,
								variants: [],
								products: [],
								notes: "",
							})
						}
					>
						{label && <Label number={15} />}
						<i
							className="fas fa-plus"
							style={{
								backgroundColor: settings.selfordering.color1,
								color: settings.selfordering.color2,
							}}
						></i>
					</button>
				)}
			{settings.selfordering.enable_flow == 0 &&
				settings.selfordering.show_inline_qty == 1 &&
				product.variants_categories.length == 0 &&
				!hasRemovableIngredients(product) && (
					<CartQtySelector
						value={cartQty}
						onChange={(qty) => updateQty(product.id, cartID, qty, product.price)}
						ifEmpty={() =>
							handleAddProduct({
								...product,
								qty: 1,
								variants: [],
								products: [],
								notes: "",
							})
						}
					/>
				)}
		</>
	);
}

export default AddToCartButton;
