import React, { useContext } from "react";
import { SettingsContext } from "../App";
import "../css/Legal.css";
import CloseButton from "./CloseButton";

function LegalPopup({ content }) {
	const { settings, setLegalPupop } = useContext(SettingsContext);

	console.log(content);

	return (
		<>
			<div className="popup legal-popup">
				<div className="content">
					<div className="popup-header">
						<CloseButton onClick={() => setLegalPupop(false)} />
						<h3>{content.title}</h3>
					</div>
					<div className="popup-scroll">
						<div className="popup-content">
							{content.content.map((el, i) => (
								<div key={i}>
									<h5>{el.title}</h5>
									<div
										className="small"
										dangerouslySetInnerHTML={{ __html: el.text }}
									></div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default LegalPopup;
