import { useContext, useState, useRef } from "react";
import { UserContext, SettingsContext, AlertContext, ShopContext, TableContext } from "../App";
import Api from "./api/api";

import "../css/User.css";
import Dashboard from "./user/Dashboard";
import Label from "./Label";
import areaCodes from "../data/areaCodes.json";
import GoogleLoginButton2 from "./GoogleLoginButton2";
import Privacy from "./Privacy";

function User({ data, setUserPopupVisible }) {
	const { settings, legal, setLegalPupop, setPagePopup, lang, accessType, setLoading } =
		useContext(SettingsContext);
	const { user, setUser } = useContext(UserContext);
	const { setAlertData } = useContext(AlertContext);
	const { shop } = useContext(ShopContext);
	const { table } = useContext(TableContext);
	const [mode, setMode] = useState("email");
	const [step, setStep] = useState(data.step || 1);
	const [email, setEmail] = useState("");
	const [areaCode, setAreaCode] = useState("");
	const [mobile, setMobile] = useState("");
	const [code, setCode] = useState("");
	const [password, setPassword] = useState("");
	const [tempUser, setTempUser] = useState(data.step ? { id: data.id } : false);
	const activationCode = useRef();

	const checkEmail = async (e) => {
		console.log("checkEmail");
		e.preventDefault();
		const response = await Api.post("/customers2/check_email/", {
			email: email,
			redirect_data:
				accessType == "shop"
					? {
							shop_id: shop.id,
							table_id: table.id,
					  }
					: null,
		});
		console.log(response);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else if (response.exist == 1) {
			setTempUser({
				id: response.id,
				name: response.name,
				email: email,
				email_confirmed: response.email_confirmed,
			});
			if (response.force_reset_password == 1) {
				setAlertData({
					message: response.error,
					confirmMessage: <Label number={31} />,
				});
				setStep(6);
			} else {
				setStep(3);
			}
		} else setStep(4);
	};

	const checkMobile = async (e) => {
		console.log("checkMobile");
		e.preventDefault();
		const response = await Api.post("/customers2/check_mobile/", {
			area_code: areaCode,
			mobile: mobile,
			redirect_data:
				accessType == "shop"
					? {
							shop_id: shop.id,
							table_id: table.id,
					  }
					: null,
		});
		console.log(response);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else if (response.exist == 1) {
			setTempUser({
				id: response.id,
				name: response.name,
				mobile: mobile,
				mobile_confirmed: response.mobile_confirmed,
			});
			if (response.mobile_confirmed == 1) {
				setStep(3);
			} else {
				setStep(8);
			}
		} else setStep(4);
	};

	const login = async (e) => {
		console.log("login");
		e.preventDefault();
		const request = {
			lang: lang,
			password: password,
		};
		if (mode == "email") request.email = email;
		if (mode == "mobile") request.mobile = mobile;
		const response = await Api.post("/customers2/login/", request);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			localStorage.setItem("customer_id", response.data.id);
			setUser(response.data);
			setUserPopupVisible(false);
		}
	};

	const handleRegistration = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		let request = Object.fromEntries(data.entries());
		request.email_confirmed = 1;
		request.shop_id = shop.id;
		request.has_code = 1;
		request.customer_lang = lang;
		request.mode = mode == "mobile" ? "sms" : "email";
		setLoading(true);
		const response = await Api.post("/customers2/add/", request);
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			setTempUser(response.data);
			setStep(8);
		}
	};

	const requestResetPassword = async (e) => {
		e.preventDefault();
		setLoading(true);
		const response = await Api.post(
			"/customers2/request_new_password_code/" + tempUser.id + "/"
		);
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			setStep(6);
		}
	};

	const checkPasswordCode = async (e) => {
		e.preventDefault();
		setLoading(true);
		const response = await Api.post(
			"/customers2/check_new_password_code/" + tempUser.id + "/",
			{
				reset_password_code: code,
			}
		);
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			setStep(7);
		}
	};

	const saveNewPassword = async (e) => {
		e.preventDefault();
		setLoading(true);
		const response = await Api.post("/customers2/edit/" + tempUser.id + "/", {
			password: password,
		});
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			setStep(2);
		}
	};

	const checkCode = async (e) => {
		console.log("checkCode");
		e.preventDefault();
		setLoading(true);
		const response = await Api.post("/customers2/verify_confirm_code/" + tempUser.id + "/", {
			mode: mode == "mobile" ? "sms" : "email",
			code: activationCode.current.value,
		});
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		else {
			localStorage.setItem("customer_id", response.data.id);
			setUser(response.data);
			setUserPopupVisible(false);
			setAlertData({
				message: <Label number={72} />,
				confirmMessage: <Label number={31} />,
			});
		}
	};

	return (
		<div id="user" className={settings.selfordering.login_at_start == 1 && !user ? "full" : ""}>
			<div className="content">
				{!user ? (
					<>
						{settings.selfordering.login_at_start == 1 &&
							settings.selfordering.logo && (
								<div className="logo">
									<img src={settings.selfordering.logo} alt="Logo" />
								</div>
							)}
						{step == 1 && (
							<form>
								<div className="title">
									<Label number={23} />
								</div>
								{settings.google.active == 1 && (
									<GoogleLoginButton2
										setUserPopupVisible={setUserPopupVisible}
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									/>
								)}
								<button
									className="button mb-3"
									style={{
										backgroundColor: settings.selfordering.color1,
										color: settings.selfordering.color2,
									}}
									onClick={() => {
										setMode("email");
										setStep(2);
									}}
								>
									<Label number={53} />
								</button>
								{settings.customers.enable_sms_login == 1 && (
									<button
										className="button mb-3"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
										onClick={() => {
											setMode("mobile");
											setStep(9);
										}}
									>
										<Label number={129} />
									</button>
								)}
								{settings.selfordering.login_at_start != 1 && (
									<button
										className="button"
										onClick={() => setUserPopupVisible(false)}
									>
										<Label number={6} />
									</button>
								)}
								<div className="mt-3 small">
									<Privacy />
									<div className="">
										Procedendo acconsenti ai nostri{" "}
										<a
											href="#"
											onClick={() =>
												setPagePopup(settings.orders.tos_page_id)
											}
										>
											Termini e Condizioni di Vendita
										</a>
									</div>
								</div>
							</form>
						)}
						{step == 2 && (
							<form onSubmit={checkEmail}>
								<div className="title">
									<Label number={23} />
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={35} />:
									</label>
									<input
										type="email"
										name="email"
										className="form-control"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										required
									/>
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(1)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={27} />
									</button>
								</div>
							</form>
						)}
						{step == 3 && (
							<form onSubmit={login}>
								<div className="title">
									<Label number={23} />
								</div>
								<div className="message">
									<Label number={28} /> {tempUser.name}
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={36} />
									</label>
									{mode == "email" && (
										<input type="hidden" name="email" defaultValue={email} />
									)}
									{mode == "mobile" && (
										<input type="hidden" name="mobile" defaultValue={mobile} />
									)}
									<input
										type="password"
										name="password"
										className="form-control"
										autoComplete="current-password"
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								</div>
								<p>
									<Label number={52} />?{" "}
									<a href="#" onClick={() => setStep(5)}>
										<Label number={51} />
									</a>
								</p>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(mode == "email" ? 2 : 9)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={23} />
									</button>
								</div>
							</form>
						)}
						{step == 4 && (
							<form onSubmit={handleRegistration}>
								<div className="title">
									<Label number={44} />
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={33} />
										*:
									</label>
									<input
										type="text"
										name="name"
										className="form-control"
										defaultValue={user.name}
										required
									/>
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={34} />
										*:
									</label>
									<input
										type="text"
										name="surname"
										className="form-control"
										required
									/>
								</div>

								{settings.customers.show_birth_date_in_registration == 1 && (
									<div className="mb-3">
										<label className="form-label">
											<Label number={92} />
											{settings.customers.birth_date_required == 1 && "*"}:
										</label>
										<input
											type="date"
											name="birth_date"
											className="form-control"
											required={settings.customers.birth_date_required == 1}
										/>
									</div>
								)}
								{settings.customers.show_gender == 1 && (
									<div className="mb-3">
										<label className="form-label">
											<Label number={93} />
											{settings.customers.gender_required == 1 && "*"}:
										</label>
										<select
											name="gender"
											className="form-select"
											required={settings.customers.gender_required == 1}
										>
											<option value=""></option>
											<option value="m">Maschio</option>
											<option value="f">Femmina</option>
										</select>
									</div>
								)}
								<div className="mb-3">
									<label className="form-label">
										<Label number={37} />
										*:
									</label>
									<div className="mobile-container">
										<select name="area_code" defaultValue={areaCode}>
											{areaCodes.map((el, i) => (
												<option key={i} value={el.dial_code}>
													{el.code + " (" + el.dial_code + ")"}
												</option>
											))}
										</select>
										<input
											type="tel"
											name="mobile"
											defaultValue={mobile}
											required
										/>
									</div>
								</div>
								{mode == "email" && (
									<div className="mb-3">
										<label className="form-label">
											<Label number={35} />
											*:
										</label>
										<input
											type="email"
											name="email"
											className="form-control"
											defaultValue={email}
											required
										/>
									</div>
								)}
								<div className="mb-3">
									<label className="form-label">
										<Label number={36} />
										*:
									</label>
									<input
										type="password"
										name="password"
										className="form-control"
										autoComplete="new-password"
										required
									/>
								</div>

								{legal &&
									Object.keys(legal).map((id, i) => {
										if (id == "newsletter" || id == "marketing") {
											return (
												<div className="form-group form-check mb-2" key={i}>
													<input
														type="checkbox"
														className="form-check-input"
														id={id}
														name={id}
														value="1"
													/>
													<label
														className="form-check-label small"
														htmlFor={id}
													>
														{legal[id].label_text}
														<a
															href="#"
															onClick={() => setLegalPupop(legal[id])}
														>
															{legal[id].label_linked}
														</a>
													</label>
												</div>
											);
										}
									})}
								<div className="mb-3 small">
									<Privacy />
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(mode == "email" ? 2 : 9)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={44} />
									</button>
								</div>
							</form>
						)}
						{step == 5 && (
							<form onSubmit={requestResetPassword}>
								<div className="title">
									<Label number={82} />
								</div>
								<div className="message">
									<Label number={46} />
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={35} />
									</label>
									<input
										type="email"
										name="email"
										className="form-control"
										onChange={(e) => setEmail(e.target.value)}
										value={email}
										required
									/>
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(3)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={45} />
									</button>
								</div>
							</form>
						)}
						{step == 6 && (
							<form onSubmit={checkPasswordCode}>
								<div className="title">
									<Label number={54} />
								</div>
								<div className="message">
									<Label number={55} />
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={56} />
									</label>
									<input
										type="number"
										name="reset_password_code"
										className="form-control"
										onChange={(e) => setCode(e.target.value)}
										required
									/>
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(5)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={69} />
									</button>
								</div>
							</form>
						)}
						{step == 7 && (
							<form onSubmit={saveNewPassword}>
								<div className="title">
									<Label number={68} />
								</div>
								<div className="message">
									<Label number={67} />
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={36} />
									</label>
									<input
										type="password"
										name="password"
										className="form-control"
										autoComplete="new-password"
										onChange={(e) => setPassword(e.target.value)}
										required
									/>
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(5)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={66} />
									</button>
								</div>
							</form>
						)}
						{step == 8 && (
							<form onSubmit={checkCode}>
								<div className="title">
									<Label number={54} />
								</div>
								<div className="message">
									{mode == "email" && <Label number={55} />}
									{mode == "mobile" && <Label number={130} />}
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={56} />
									</label>
									<input
										type="number"
										name="reset_password_code"
										className="form-control"
										ref={activationCode}
										required
									/>
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(mode == "email" ? 5 : 9)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={69} />
									</button>
								</div>
							</form>
						)}
						{step == 9 && (
							<form onSubmit={checkMobile}>
								<div className="title">
									<Label number={23} />
								</div>
								<div className="mb-3">
									<label className="form-label">
										<Label number={37} />:
									</label>
									<div className="mobile-container">
										<select
											name="area_code"
											onChange={(e) => setAreaCode(e.target.value)}
											value={areaCode}
										>
											{areaCodes.map((el, i) => (
												<option key={i} value={el.dial_code}>
													{el.code + " (" + el.dial_code + ")"}
												</option>
											))}
										</select>
										<input
											type="tel"
											name="mobile"
											onChange={(e) => setMobile(e.target.value)}
											value={mobile}
											required
										/>
									</div>
								</div>
								<div className="buttons">
									<button
										type="button"
										className="button"
										onClick={() => setStep(1)}
									>
										<Label number={26} />
									</button>
									<button
										type="submit"
										className="button"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										<Label number={27} />
									</button>
								</div>
							</form>
						)}
					</>
				) : (
					<>
						<Dashboard setUserPopupVisible={setUserPopupVisible} />
					</>
				)}
			</div>
		</div>
	);
}

export default User;
