import { useContext, useEffect, useState } from "react";
import { CategoriesContext, SettingsContext } from "../App";
import ProductsList from "./ProductsList";
import CategoriesTags from "./CategoriesTags.js";
import Label from "./Label.js";

function Products({ src = false }) {
	const { category } = useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);
	const [data, setData] = useState(false);

	useEffect(() => {
		console.log("src", src);
		if (src)
			setData({ name: <Label number={133} />, description: "", tags: [], products: src });
		else setData(category);
	}, [category, src]);

	if (!data) return null;

	return (
		<>
			<div className="category-title-container">
				<h3 className="category-title">{data.name}</h3>
				<CategoriesTags category={data} />
			</div>
			{category.description && <p className="category-description">{data.description}</p>}
			<ProductsList products={data.products} />
		</>
	);
}

export default Products;
