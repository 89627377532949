import { useContext, useEffect, useState } from "react";
import { CartContext, SettingsContext } from "../../App";
import Product from "./Product";

function Products({ products, timerActive, updateQty }) {
	const { settings } = useContext(SettingsContext);
	const { cart } = useContext(CartContext);
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		if (settings.selfordering.enable_flow == 1) {
			let arr = JSON.parse(JSON.stringify(products));
			arr.sort(function (a, b) {
				return parseInt(a.flow) < parseInt(b.flow) ? -1 : 1;
			});
			setFilteredProducts(arr);
		} else setFilteredProducts(products);
	}, [products]);

	let prevFlow = null;
	function printFlow(flow) {
		if (flow != prevFlow) {
			prevFlow = flow;
			return <div className="cart-flow-line">Portata {flow}</div>;
		}
	}

	return (
		<>
			{cart.allyoucaneat == 1 && settings.selfordering?.disable_timer_for_priced == 1 ? (
				<>
					<h5 className="mt-3 mb-0">Puoi ordinare</h5>
					<div>
						{products.map(
							(product, i) =>
								(product.price > 0 || !timerActive) && (
									<Product
										key={i}
										index={i}
										product={product}
										timerActive={timerActive}
										updateQty={updateQty}
									/>
								)
						)}
					</div>
					{timerActive && (
						<h5 className="mt-5 mb-0">Attesa prossimo ordine {timerActive}</h5>
					)}
					<div>
						{products.map(
							(product, i) =>
								product.price == 0 &&
								timerActive && (
									<Product
										key={i}
										product={product}
										timerActive={timerActive}
										updateQty={updateQty}
									/>
								)
						)}
					</div>
				</>
			) : (
				filteredProducts.map((product, i) => (
					<div key={i}>
						{printFlow(product.flow)}
						<Product
							product={product}
							timerActive={timerActive}
							updateQty={updateQty}
						/>
					</div>
				))
			)}
		</>
	);
}

export default Products;
