import { useContext } from "react";
import Api from "../api/api";
import logo from "../../images/vivawallet.svg";
import { SettingsContext, CartContext, TableContext, ShopContext, UserContext } from "../../App";

function Vivawallet({ onPayment }) {
	const { settings, accessType, userID, monoOrder, setLoading } = useContext(SettingsContext);
	const { shop, brand } = useContext(ShopContext);
	const { table } = useContext(TableContext);
	const { cart } = useContext(CartContext);
	const { user } = useContext(UserContext);

	const pay = async (e) => {
		e.preventDefault();
		const request = {
			customer_id: user.id,
			shop_id: cart.shop_id,
			table_id: table.id,
			total: cart.total,
			origin: "selfordering",
			tags: [
				"origin:selfordering",
				"shop_id:" + shop.id,
				"table_id:" + table.id,
				"user_id:" + userID,
				"mono_order:" + monoOrder,
				"access_type:" + accessType,
				"brand_id:" + brand.id || null,
			],
		};
		console.log(request);
		setLoading(true);
		const response = await Api.post("/vivawallet/init/", request);
		setLoading(false);
		console.log(response);
		if (response.success === 0) alert(response.error);
		else {
			console.log(response.redirect_url);
			document.location = response.redirect_url;
		}
	};

	return (
		<>
			<button
				onClick={pay}
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
			>
				{shop.payment_methods[12].label}
			</button>
			<div className="disclaimer">
				Payment powered by
				<div>
					<img src={logo} />
				</div>
			</div>
		</>
	);
}

export default Vivawallet;
