import { useContext, useState } from "react";
import { SettingsContext } from "../App";
import "../css/Pin.css";
import LangSelector from "./LangSelector";
import Label from "./Label";

function Covers() {
	const { settings, editTable } = useContext(SettingsContext);
	const [inputValue, setInputValue] = useState("");

	const handleSubmit = async (e) => {
		e.preventDefault();
		editTable({
			covers: inputValue,
		});
	};

	return (
		<div id="pin">
			<div className="logo">
				<img src={settings.selfordering.logo_home} alt="Logo" />
			</div>
			<form
				className="form"
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
				onSubmit={handleSubmit}
			>
				<div className="content">
					<h2>
						<Label number={28} />!
					</h2>
					<p>
						<Label number={60} />
					</p>
					<div className="form-line">
						<div
							className="label"
							style={{
								backgroundColor: settings.selfordering.color2,
								color: settings.selfordering.color1,
							}}
						>
							<Label number={61} />
						</div>
						<input
							type="number"
							id="pin_input"
							value={inputValue}
							onChange={(e) => setInputValue(e.target.value)}
							min="1"
							max="99"
							required
						></input>
					</div>
					<button
						style={{
							backgroundColor: settings.selfordering.color3,
							color: settings.selfordering.color4,
						}}
					>
						<Label number={64} />
					</button>
					{settings.selfordering.multilanguage == 1 && (
						<div className="lang-selector-container">
							<LangSelector />
						</div>
					)}
				</div>
			</form>
		</div>
	);
}

export default Covers;
