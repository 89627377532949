import React, { Fragment, useContext, useEffect, useState } from "react";
import Api from "./api/api";
import { SettingsContext, ShopContext, UserContext } from "../App";
import "../css/Legal.css";
import CloseButton from "./CloseButton";
import Label from "./Label";
import currencyFormat from "./functions/functions";

function PromoPopup() {
	const { lang, setPromoPopup } = useContext(SettingsContext);
	const { shop } = useContext(ShopContext);
	const { user } = useContext(UserContext);
	const [discountsFidelity, setDiscountsFidelity] = useState(false);
	const [discountsCode, setDiscountsCode] = useState(false);
	const [creditCodes, setCreditCodes] = useState(false);

	useEffect(() => {
		loadDiscountsFidelity(); // eslint-disable-next-line
		loadDiscountsCode(); // eslint-disable-next-line
		if (user) {
			loadCreditCodes(); // eslint-disable-next-line
		}
	}, []);

	const loadDiscountsFidelity = async () => {
		const response = await Api.post("/discounts_fidelity/list/", {
			orderby: "name",
			lang: lang,
		});
		if (response.success == 0) alert(response.error);
		else setDiscountsFidelity(response.rows);
	};

	const loadDiscountsCode = async () => {
		const response = await Api.post("/discounts/list/", {
			src: [{ name: "public", value: 1, compare: "equal" }],
		});
		if (response.success == 0) alert(response.error);
		else setDiscountsCode(response.rows);
	};

	const loadCreditCodes = async () => {
		const response = await Api.post("/credits_codes/list/", {
			src: [{ name: "buyer_id", value: user.id, compare: "equal" }],
		});
		if (response.success == 0) alert(response.error);
		else setCreditCodes(response.rows);
	};

	return (
		<div className="popup legal-popup">
			<div className="content">
				<div className="popup-header">
					<CloseButton onClick={() => setPromoPopup(false)} />
					<h3 className="mb-0">
						<Label number={110} />
					</h3>
				</div>
				<div className="popup-scroll">
					<div className="popup-content">
						{discountsFidelity && (
							<div>
								<h4>
									<Label number={111} />
								</h4>
								<div className="table table-3">
									<div className="table-head">
										<Label number={96} />
									</div>
									<div className="table-head text-center">
										<Label number={114} />
									</div>
									<div className="table-head text-center">
										<Label number={115} />
									</div>
									{discountsFidelity.map((discount, i) => (
										<Fragment key={i}>
											<div>{discount.name}</div>
											<div className="text-center">{discount.points}</div>
											<div className="text-center">
												{discount.value_type == "fixed" &&
													currencyFormat(
														discount.value,
														false,
														shop.currency
													)}
												{discount.value_type == "perc" &&
													discount.value + "%"}
											</div>
										</Fragment>
									))}
								</div>
							</div>
						)}
						{discountsCode && (
							<div>
								<h4>
									<Label number={112} />
								</h4>
								<div className="table table-3">
									<div className="table-head">
										<Label number={96} />
									</div>
									<div className="table-head text-center">
										<Label number={14} />
									</div>
									<div className="table-head text-center">
										<Label number={115} />
									</div>
									{discountsCode.map((discount, i) => (
										<Fragment key={i}>
											<div>{discount.name}</div>
											<div className="text-center">{discount.code}</div>
											<div className="text-center">
												{discount.value_type == "fixed" &&
													currencyFormat(
														discount.value,
														false,
														shop.currency
													)}
												{discount.value_type == "perc" &&
													discount.value + "%"}
											</div>
										</Fragment>
									))}
								</div>
							</div>
						)}
						{creditCodes && creditCodes.length > 0 && (
							<div>
								<h4>
									<Label number={113} />
								</h4>
								<div className="table table-2">
									<div className="table-head">
										<Label number={96} />
									</div>
									<div className="table-head text-center">
										<Label number={116} />
									</div>
									{creditCodes.map((discount, i) => (
										<Fragment key={i}>
											<div>
												{discount.name}
												<div className="small">
													<Label number={56} />: {discount.code}
												</div>
												<div className="small">
													<Label number={115} />:{" "}
													{currencyFormat(
														discount.value,
														false,
														shop.currency
													)}
												</div>
											</div>
											<div className="text-center">
												{discount.used == 1 ? (
													<i className="fas fa-check success" />
												) : (
													<i className="fas fa-times error" />
												)}
											</div>
										</Fragment>
									))}
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default PromoPopup;
