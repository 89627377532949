import { useContext, useEffect } from "react";
import { CartContext, SettingsContext } from "../App";
import Label from "./Label";

export default function EmailPopup() {
	const { settings, setUserPopupVisible, handleCheckout, setEmailPopupVisible, editCart } =
		useContext(SettingsContext);
	const { cart } = useContext(CartContext);

	useEffect(() => {
		if (cart.email) {
			setEmailPopupVisible(false);
			handleCheckout();
		}
	}, [cart]);

	const saveEmail = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		const request = Object.fromEntries(data.entries());
		await editCart(request);
	};

	return (
		<div className="email-popup">
			<div className="content">
				{settings.selfordering.enable_login == 1 && (
					<>
						<div>
							<Label number={136} />
						</div>
						<button
							onClick={() => {
								setEmailPopupVisible(false);
								setUserPopupVisible(true);
							}}
							className="primary"
						>
							<Label number={23} />
						</button>
					</>
				)}
				{settings.selfordering.enable_email == 1 && (
					<>
						<div>
							<Label number={137} />
						</div>
						<form onSubmit={saveEmail}>
							<label>Email:</label>
							<input type="email" name="email" defaultValue={cart.email} required />
							<button className="primary">
								<Label number={138} />
							</button>
						</form>
					</>
				)}
				{settings.selfordering.mandatory_email != 1 && (
					<>
						<div>
							<Label number={135} />
						</div>
						<button
							className="primary"
							onClick={() => {
								setEmailPopupVisible(false);
								handleCheckout(true);
							}}
						>
							<Label number={139} />
						</button>
					</>
				)}

				<button onClick={() => setEmailPopupVisible(false)}>
					<Label number={6} />
				</button>
			</div>
		</div>
	);
}
