import { useContext, useState } from "react";
import { SettingsContext, ShopContext, TableContext, AlertContext } from "../App";
import Api from "./api/api";
import "../css/Pin.css";
import LangSelector from "./LangSelector";
import Label from "./Label";

function Pin() {
	const { settings, lang, accessType, setPin } = useContext(SettingsContext);
	const { shop, load_shop } = useContext(ShopContext);
	const { table, load_table } = useContext(TableContext);
	const { setAlertData } = useContext(AlertContext);
	const [inputValue, setInputValue] = useState("");

	const checkPin = async (e) => {
		e.preventDefault();
		console.log("pin_mode", settings.selfordering.pin_mode);
		if (
			settings.selfordering.pin_mode == "generated" ||
			settings.selfordering.pin_mode == "fixed"
		) {
			const response = await Api.post("/self_cart/check_pin/", {
				pin: inputValue,
				lang: lang,
			});
			if (response.success === 0)
				setAlertData({
					title: <Label number={70} />,
					message: response.error,
					confirmMessage: <Label number={31} />,
				});
			else {
				if (table && table.id != response.data.id)
					setAlertData({
						title: <Label number={70} />,
						message: "Pin errato",
						confirmMessage: <Label number={31} />,
					});
				else {
					if (settings.selfordering.pin_mode == "fixed") {
						const openReaponse = await Api.post(
							"/self_cart/open/" + response.data.id + "/"
						);
						if (openReaponse.success === 0)
							setAlertData({
								title: <Label number={70} />,
								message: openReaponse.error,
								confirmMessage: <Label number={31} />,
							});
						else {
							load_shop(response.data.shop_id);
							load_table(response.data.id);
							setPin(inputValue);
							localStorage.setItem("pin", inputValue);
						}
					} else {
						load_shop(response.data.shop_id);
						load_table(response.data.id);
						setPin(inputValue);
						localStorage.setItem("pin", inputValue);
					}
				}
			}
		}
		if (settings.selfordering.pin_mode == "shop") {
			if (parseInt(shop.selfordering_pin) == parseInt(inputValue)) {
				const response = await Api.post("/self_cart/open/" + table.id + "/");
				if (response.success === 0)
					setAlertData({
						title: <Label number={70} />,
						message: response.error,
						confirmMessage: <Label number={31} />,
					});
				else {
					setPin(inputValue);
					localStorage.setItem("pin", inputValue);
				}
			} else {
				setAlertData({
					title: <Label number={70} />,
					message: <Label number={71} />,
					confirmMessage: <Label number={31} />,
				});
			}
		}
		if (settings.selfordering.pin_mode == "disabled") {
			const response = await Api.post("/self_cart/open/" + table.id + "/");
			if (response.success === 0)
				setAlertData({
					title: <Label number={70} />,
					message: response.error,
					confirmMessage: <Label number={31} />,
				});
			else {
				setPin("not_required");
				localStorage.setItem("pin", "not_required");
			}
		}
	};

	return (
		<div id="pin">
			<div className="logo">
				<img src={settings.selfordering.logo_home} alt="Logo" />
			</div>
			<form
				className="form"
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
				onSubmit={checkPin}
			>
				<div className="content">
					<h2>
						<Label number={28} />
					</h2>
					{accessType == "table" &&
					(settings.selfordering.pin_mode == "shop" ||
						settings.selfordering.pin_mode == "disabled") ? (
						<p>Modalità di accesso non supportata</p>
					) : (
						<>
							{settings.selfordering.pin_mode != "disabled" && (
								<>
									<p>
										<Label number={65} />
									</p>
									<div className="form-line">
										<div
											className="label"
											style={{
												backgroundColor: settings.selfordering.color2,
												color: settings.selfordering.color1,
											}}
										>
											<Label number={59} />
										</div>
										<input
											type="number"
											inputMode="numeric"
											id="pin_input"
											value={inputValue}
											onChange={(e) => setInputValue(e.target.value)}
											required
										></input>
									</div>
								</>
							)}
							<button
								style={{
									backgroundColor: settings.selfordering.color3,
									color: settings.selfordering.color4,
								}}
							>
								<Label number={64} />
							</button>
						</>
					)}
					{settings.selfordering.multilanguage == 1 && (
						<div className="lang-selector-container">
							<LangSelector />
						</div>
					)}
				</div>
			</form>
		</div>
	);
}

export default Pin;
