import { useContext, useEffect, useState } from "react";
import currencyFormat from "./functions/functions";
import "../css/QtySelector.css";
import { ShopContext } from "../App";

function QtySelector({ variant, onChange, hasPrice }) {
	const { shop } = useContext(ShopContext);
	const [qty, setQty] = useState(parseInt(variant.is_default > 0) ? 1 : 0);

	useEffect(() => {
		onChange();
	}, [qty]);

	const increment = (e) => {
		if (e.currentTarget.classList.contains("enabled")) setQty(qty + 1);
	};

	const decrement = () => {
		setQty(qty > 0 ? qty - 1 : 0);
	};

	return (
		<div className="qty-selector">
			<button onClick={decrement} className={"minus" + (qty > 0 ? " enabled" : "")}>
				<i className="fas fa-minus"></i>
			</button>
			<div className="qty" data-qty={qty}>
				{qty}
			</div>
			<button onClick={increment} className="plus">
				<i className="fas fa-plus"></i>
			</button>
			<div className="qty-label">
				{variant.name}
				{hasPrice == 1 && (
					<div className="small">
						{" +" + currencyFormat(variant.price, false, shop.currency)}
					</div>
				)}
			</div>
		</div>
	);
}

export default QtySelector;
