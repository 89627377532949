import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import currencyFormat from "../functions/functions";

function OrderSheet({ order, setOrder }) {
	if (!order) return null;

	return (
		<div className="order-sheet">
			<h3>Ordine N°{order.id}</h3>
			<p className="mb-0">
				Data:{" "}
				<Moment format={"DD/MM/YYYY LT"} locale="it">
					{order.delivery_date}
				</Moment>
			</p>
			<p className="mb-0">Modalità: {order.delivery_type}</p>
			<p className="mb-0">Origine: {order.origin}</p>
			<p className="mb-0 bold">Prodotti:</p>
			{order.products.map((product, i) => (
				<div key={i}>
					<p className="mb-0">
						{product.qty}x {product.name} -{" "}
						{currencyFormat(product.price, false, order.currency)}
					</p>
					<hr />
				</div>
			))}
			<p className="bold">Totale: {currencyFormat(order.total, false, order.currency)}</p>
			<button className="basic-button" onClick={() => setOrder(false)}>
				Torna alla lista ordini
			</button>
		</div>
	);
}

export default OrderSheet;
