import { useContext, useEffect } from "react";
import "../css/Menu.css";
import { CategoriesContext, SettingsContext, AlertContext, ShopContext } from "../App";
import Label from "./Label";
import CategoriesTags from "./CategoriesTags.js";

function Menu({ setMenuVisible }) {
	const { settings, setCourse, setCategory, setPin, pages, setPagePopup, kioskMode } =
		useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);
	const { setAlertData } = useContext(AlertContext);
	const { shop, brand } = useContext(ShopContext);

	useEffect(() => {
		const header = document.querySelector(".header");
		const r = document.querySelector(":root");
		r.style.setProperty("--header-height", header.offsetHeight + "px");
		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, []);

	const handleResize = () => {
		const menu = document.getElementById("menu");
		if (window.innerWidth >= 768) menu.style.display = "block";
		else menu.style.display = "none";
	};

	const handleSetCategory = (course, category) => {
		console.log("handleSetCategory");
		setCourse(course);
		setCategory(category);
		closeMenu();
	};

	const closeMenu = () => {
		console.log("closeMenu");
		setMenuVisible(false);
	};

	return (
		<div id="menu">
			<div className="">
				{settings.selfordering.categories_menu_type == 1 &&
					categories.map((course, i) => (
						<div key={i}>
							{course.name && <div className="course-title">{course.name}</div>}
							{course.categories.map((category, n) => (
								<button
									key={n}
									onClick={() => handleSetCategory(course, category)}
									className="button"
									style={{
										backgroundColor: settings.selfordering.color1,
										color: settings.selfordering.color2,
									}}
								>
									{category.name}
									<CategoriesTags category={category} />
								</button>
							))}
						</div>
					))}
				{pages && (
					<>
						<hr />
						{pages.map((page, i) =>
							page.url ? (
								<a
									key={i}
									href={page.url}
									target="_blank"
									className="button"
									style={{
										backgroundColor: settings?.selfordering?.color3,
										color: settings?.selfordering?.color4,
									}}
								>
									{page.title}
								</a>
							) : (
								<a
									key={i}
									onClick={() => setPagePopup(page.id)}
									className="button"
									style={{
										backgroundColor: settings?.selfordering?.color3,
										color: settings?.selfordering?.color4,
									}}
								>
									{page.title}
								</a>
							)
						)}
					</>
				)}
				<hr />
				{shop.selfordering_show_menu == 1 &&
					(!brand || settings.selfordering.show_menu == 1) && (
						<a
							href={
								settings.menu?.url +
								"?shop_id=" +
								shop.id +
								(brand ? "&brand_id=" + brand.id : "")
							}
							target="_blank"
							className="button"
							style={{
								backgroundColor: settings?.selfordering?.color3,
								color: settings?.selfordering?.color4,
							}}
						>
							<Label number={74} />
						</a>
					)}
				<a
					href="#"
					className="button"
					style={{
						backgroundColor: settings.selfordering.color3,
						color: settings.selfordering.color4,
					}}
					onClick={() =>
						setAlertData({
							title: <Label number={47} />,
							showCancelButton: true,
							callback: () => {
								setPin(false);
								localStorage.removeItem("pin");
							},
						})
					}
				>
					<Label number={17} />
				</a>
				<div className="version">
					{kioskMode ? (
						<>Powered by YellGO</>
					) : (
						<a href="https://www.yellgo.it" target="_blank">
							Powered by YellGO
						</a>
					)}
				</div>
			</div>
		</div>
	);
}

export default Menu;
