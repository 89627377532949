import "../css/Alert.css";
import Label from "./Label";

function Alert({ alertData, setAlertData }) {
	const handleConfirm = () => {
		setAlertData(false);
		if (typeof alertData.callback === "function") alertData.callback();
	};

	return (
		<div id="alert">
			<div className="content">
				<div className="title">{alertData.title}</div>
				<div className="message">{alertData.message}</div>
				<div className="buttons">
					{alertData.showCancelButton && (
						<button className="button danger" onClick={() => setAlertData(false)}>
							{alertData.cancelMessage ? (
								<>{alertData.cancelMessage}</>
							) : (
								<Label number={30} />
							)}
						</button>
					)}
					<button className="button success" onClick={handleConfirm}>
						{alertData.confirmMessage ? (
							<>{alertData.confirmMessage}</>
						) : (
							<Label number={29} />
						)}
					</button>
				</div>
			</div>
		</div>
	);
}

export default Alert;
