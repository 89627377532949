import React, { useContext, useEffect } from "react";
import { SettingsContext } from "../App";
import CloseButton from "./CloseButton";
import Label from "./Label";
import ProductsList from "./ProductsList";

function RelatedProductsPopup() {
	const { relatedProducts, setRelatedProducts, setLoadRelated } = useContext(SettingsContext);

	useEffect(() => {
		setLoadRelated(false);
		return () => setLoadRelated(true);
	}, []);

	return (
		<div className="popup">
			<div className="content">
				<div className="popup-header">
					<CloseButton onClick={() => setRelatedProducts(false)} />
					<h3 className="mb-0">
						<Label number={131} />
					</h3>
				</div>
				<div className="popup-scroll">
					<div className="popup-content">
						<p className="text-center">
							<Label number={132} />
						</p>
						<ProductsList products={relatedProducts} />
					</div>
				</div>
			</div>
		</div>
	);
}

export default RelatedProductsPopup;
