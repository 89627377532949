import "../css/Loading.css";

function Loading() {
	return (
		<div id="loading">
			<div className="content">
				<i className="fas fa-solid fa-circle-notch fa-spin fa-2x"></i>
			</div>
		</div>
	);
}

export default Loading;
