import { useContext, useEffect, useState } from "react";
import { SettingsContext, ShopContext, UserContext } from "../../App";
import Api from "../api/api";
import Moment from "react-moment";
import "moment-timezone";
import "moment/locale/it";
import Label from "../Label";

function Fidelity() {
	const { settings, setLoading } = useContext(SettingsContext);
	const { user } = useContext(UserContext);
	const { shop } = useContext(ShopContext);
	const [points, setPoints] = useState([]);
	const [sale, setSale] = useState(false);

	useEffect(() => {
		loadOrders();
	}, []);

	const loadOrders = async () => {
		setLoading(true);
		let request = {
			src: [{ name: "customer_id", value: user.id, compare: "equal" }],
			orderby: "date_insert DESC",
		};
		if (shop && settings.fidelity?.split_points == 1) {
			request.src.push({ name: "shop_id", value: shop.id, compare: "equal" });
		}
		const res = await Api.post("/points_transactions/list/", request);
		setLoading(false);
		console.log(res);
		setPoints(res.rows);
		let tot = 0;
		for (const el of res.rows) {
			tot += parseInt(el.points);
		}
		setSale(tot);
	};

	return (
		<div className="verticalContainer">
			<div className="title">
				<Label number={95} />
				<div className="medium">
					<Label number={85} />: {sale}
				</div>
			</div>
			<div className="scrollContent">
				<table className="table mb-0">
					<tbody>
						<tr>
							<th>
								<Label number={96} />
							</th>
							<th>
								<Label number={97} />
							</th>
							<th>
								<Label number={98} />
							</th>
						</tr>
						{points.map((point, i) => (
							<tr key={i}>
								<td>
									{point.name}
									<div className="small">
										<Moment format={"DD/MM/YYYY LT"} locale="it">
											{point.date_insert}
										</Moment>
									</div>
								</td>

								<td className="text-center">{point.order_id}</td>
								<td className="text-center">{point.points}</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</div>
	);
}

export default Fidelity;
