import { useContext } from "react";
import { SettingsContext, ShopContext } from "../App";
import "../css/Lock.css";
import Label from "./Label";

function Lock({ message }) {
	const { settings } = useContext(SettingsContext);
	const { shop, brand } = useContext(ShopContext);

	return (
		<div
			id="lock"
			style={{
				backgroundColor: settings?.selfordering?.color1,
				color: settings?.selfordering?.color2,
			}}
		>
			<div className="content">
				{message == "maintenance" ? <Label number={127} /> : message}
				{shop.selfordering_show_menu == 1 &&
					(!brand || settings.selfordering.show_menu == 1) && (
						<a
							href={
								settings.menu?.url +
								"?shop_id=" +
								shop.id +
								(brand ? "&brand_id=" + brand.id : "")
							}
							target="_blank"
							className="button"
							style={{
								backgroundColor: settings?.selfordering?.color3,
								color: settings?.selfordering?.color4,
							}}
						>
							Sfoglia il menu
						</a>
					)}
			</div>
		</div>
	);
}

export default Lock;
