import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../App";
import "../css/MonoOrderConfirm.css";
import Label from "./Label";
import api from "./api/api";

function MonoOrderConfirm({ onClose }) {
	const { settings, monoOrderConfirmVisible } = useContext(SettingsContext);
	const [order, setOrder] = useState(false);

	useEffect(() => {
		if (monoOrderConfirmVisible.id) loadOrder();
	}, [monoOrderConfirmVisible]);

	async function loadOrder() {
		console.log("load_customer");
		const response = await api.post("/orders/get/" + monoOrderConfirmVisible.id + "/");
		if (response.success === 0) alert(response.error);
		setOrder(response.data);
	}

	return (
		<div
			className="mono-order-confirm"
			style={{
				backgroundColor: settings.selfordering.color1,
			}}
		>
			<div className="content">
				<h4>
					<Label number={140} />
				</h4>
				<span>
					<Label number={141} />
				</span>
				<h1>{order.number}</h1>
				<h4>ID: {monoOrderConfirmVisible.id}</h4>
				<img
					src={
						"https://api.yellgo.cloud/class/barcode.php?f=png&s=qr&p=0&w=500&h=500&d=" +
						monoOrderConfirmVisible.id
					}
				/>

				<button
					onClick={onClose}
					className="button"
					style={{
						backgroundColor: settings.selfordering.color1,
						color: settings.selfordering.color2,
					}}
				>
					<Label number={142} />
				</button>
			</div>
		</div>
	);
}

export default MonoOrderConfirm;
