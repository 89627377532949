import { useContext, useEffect, useState } from "react";
import { SettingsContext, ShopContext } from "../App";
import api from "./api/api";
import { getTranslatedString } from "./functions/functions";

function PopUps() {
	const { lang } = useContext(SettingsContext);
	const { shop } = useContext(ShopContext);
	const [popUps, setPopUps] = useState(false);
	const [popUpsVisible, setPopUpsVisible] = useState([]);

	useEffect(() => {
		if (shop) loadPopUps();
	}, [shop]);

	useEffect(() => {
		if (shop) setPopUpsVisible(popUps);
	}, [lang]);

	async function loadPopUps() {
		console.log("loadPopUps");
		const response = await api.post("/popups/list/", {
			src: [
				{
					name: "active",
					value: 1,
					compare: "equal",
				},
				{
					name: "selforder",
					value: 1,
					compare: "equal",
				},
				{
					name: "shops",
					value: shop.id,
					compare: "equal",
					type: "join",
					join_table: "shops_popups_relations",
					join_field: "popup_id",
					src_field: "shop_id",
				},
			],
		});
		if (response.success === 0) alert(response.error);
		setPopUpsVisible(response.rows);
		setPopUps(response.rows);
	}

	const handleClose = (n) => {
		setPopUpsVisible(popUpsVisible.filter((el, i) => i != n));
	};

	return (
		<>
			{popUpsVisible &&
				popUpsVisible.map((p, i) => (
					<div key={i} className="promo-popup">
						<div className="content">
							{p.img && (
								<div className="img">
									<img src={p.img} alt={p.title} />
								</div>
							)}
							<div className="title">{getTranslatedString(p, "title", lang)}</div>
							<div
								dangerouslySetInnerHTML={{
									__html: getTranslatedString(p, "text", lang),
								}}
							/>
							<button onClick={() => handleClose(i)}>
								{getTranslatedString(p, "button_label", lang)}
							</button>
						</div>
					</div>
				))}
		</>
	);
}

export default PopUps;
