import { useContext } from "react";
import { SettingsContext } from "../App";
import Label from "./Label";

function TempUserPopup({ onCancel, onSubmit }) {
	const { settings } = useContext(SettingsContext);

	const save = async (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		const request = Object.fromEntries(data.entries());
		onSubmit(request);
	};

	return (
		<div className="temp-user-popup">
			<div className="content">
				<div className="mb-3">
					<Label number={128} />
				</div>
				<form onSubmit={save}>
					<div className="mb-3">
						<label className="form-label">
							<Label number={33} />:
						</label>
						<input type="text" name="name" className="form-control" required />
					</div>
					<div className="mb-3">
						<label className="form-label">
							<Label number={34} />:
						</label>
						<input type="text" name="surname" className="form-control" required />
					</div>
					<div className="mb-3">
						<label className="form-label">
							<Label number={35} />:
						</label>
						<input type="email" name="email" className="form-control" required />
					</div>
					<button
						type="submit"
						className="button mb-3"
						style={{
							backgroundColor: settings.selfordering.color1,
							color: settings.selfordering.color2,
						}}
					>
						<Label number={27} />
					</button>
					<button type="button" className="button" onClick={onCancel}>
						<Label number={6} />
					</button>
				</form>
			</div>
		</div>
	);
}

export default TempUserPopup;
