import { useContext, useEffect, useState } from "react";
import { SettingsContext, ShopContext } from "../App";
import LangSelector from "./LangSelector";
import Label from "./Label";
import "../css/Pin.css";

function KioskHome() {
	const { settings, open_temp_cart } = useContext(SettingsContext);
	const { shop } = useContext(ShopContext);

	const login = async (e) => {
		console.log("login");
		if (e != undefined) e.preventDefault();
		open_temp_cart(shop.id, null);
	};

	return (
		<div id="pin">
			<div className="logo">
				<img src={settings.selfordering.logo_home} alt="Logo" />
			</div>
			<div
				className="form"
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
			>
				<form className="content">
					<h2>
						<Label number={28} />
					</h2>
					<button
						type="button"
						onClick={login}
						style={{
							backgroundColor: settings.selfordering.color3,
							color: settings.selfordering.color4,
						}}
					>
						<Label number={64} />
					</button>
					{settings.selfordering.multilanguage == 1 && (
						<div className="lang-selector-container">
							<LangSelector />
						</div>
					)}
				</form>
			</div>
		</div>
	);
}

export default KioskHome;
