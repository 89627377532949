import { useState } from "react";
import Label from "../Label";

function NotesPopup({ value = "", onChange, onClose }) {
	const [notes, setNotes] = useState(value);

	return (
		<div className="payment-popup">
			<div className="content">
				<div className="title">
					<Label number={4} />
				</div>
				<textarea value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
				<button
					className="button primary"
					onClick={() => {
						onChange(notes);
						onClose();
					}}
				>
					<Label number={5} />
				</button>
				<button className="button" onClick={onClose}>
					<Label number={6} />
				</button>
			</div>
		</div>
	);
}

export default NotesPopup;
