import { useContext, useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import Api from "../api/api";
import {
	SettingsContext,
	CartContext,
	TableContext,
	AlertContext,
	ShopContext,
	UserContext,
} from "../../App";

function Stripe({ onPayment }) {
	const { settings, setSettings } = useContext(SettingsContext);
	const { shop, brand } = useContext(ShopContext);
	const { cart, setCart } = useContext(CartContext);
	const { table } = useContext(TableContext);
	const { user, setUser } = useContext(UserContext);
	const [isReady, setIsReady] = useState(false);
	const [options, setOptions] = useState(false);
	const [paymentId, setPaymentId] = useState(false);

	useEffect(() => {
		loadOptions();
	}, []);

	useEffect(() => {
		if (options) setIsReady(true);
	}, [options]);

	const stripePromise = loadStripe(shop.payment_methods[9].publicKey);

	const loadOptions = async () => {
		let label = "Nuovo ordine da " + table.name;
		if (user) label += " Cliente: " + user.surname + " " + user.name + " (" + user.id + ")";

		const request = {
			total: cart.total,
			shop_id: shop.id,
			customer_id: user.id,
			customer_name: user.name,
			customer_surname: user.surname,
			customer_email: user.email,
			origin: "selfordering",
			brand_id: brand.id,
			label: label,
		};
		console.log("mandant", settings.mandant.id);
		const response = await Api.post(
			"/plugins/stripe/createPaymentIntent/" + settings.mandant.id + "/",
			request
		);
		console.log(response);
		setOptions({
			clientSecret: response.result.clientSecret,
		});
		setPaymentId(response.result.payment_id);
	};

	if (!isReady || !paymentId) return <div>Loading</div>;

	return (
		<Elements stripe={stripePromise} options={options}>
			<CheckoutForm onPayment={onPayment} paymentId={paymentId} />
		</Elements>
	);
}

const CheckoutForm = ({ onPayment, paymentId }) => {
	const stripe = useStripe();
	const elements = useElements();
	const { settings, setLoading } = useContext(SettingsContext);
	const { user, setUser } = useContext(UserContext);
	const { alertData, setAlertData } = useContext(AlertContext);
	const { shop } = useContext(ShopContext);

	const handleSubmit = async (event) => {
		console.log("handleSubmit");
		// We don't want to let default form submission happen here,
		// which would refresh the page.
		event.preventDefault();

		try {
			if (!stripe || !elements) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}

			setLoading(true);

			const result = await stripe.confirmPayment({
				//`Elements` instance that was used to create the Payment Element
				elements,
				confirmParams: {
					return_url: window.location.protocol + "//" + window.location.host,
				},
				redirect: "if_required",
			});
			console.log("stripe.confirmPayment", result);

			if (result.error) {
				// Show error to your customer (for example, payment details incomplete)
				setLoading(false);
				console.log(result.error.message);
			} else {
				setLoading(false);
				onPayment(9, paymentId);
			}
		} catch (error) {
			setLoading(false);
			console.error("Loading error", error);
			// expected output: ReferenceError: nonExistentFunction is not defined
			// Note - error messages will vary depending on browser
		}
	};

	return (
		<form onSubmit={handleSubmit}>
			<PaymentElement />
			{stripe && elements && (
				<button
					className="mt-2"
					disabled={!stripe}
					style={{
						backgroundColor: settings.selfordering.color1,
						color: settings.selfordering.color2,
					}}
				>
					{shop.payment_methods[9].label}
				</button>
			)}
		</form>
	);
};

export default Stripe;
