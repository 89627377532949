import { useEffect, useState } from "react";

function FlowPopUp({ data, add_product, settings }) {
	const [flows, setFlows] = useState([]);

	useEffect(() => {
		let flow_number = 5;
		if (settings.selfordering.flow_number)
			flow_number = parseInt(settings.selfordering.flow_number);
		let f = [];
		for (let index = 1; index <= flow_number; index++) {
			f.push(index);
		}
		setFlows(f);
	}, []);

	return (
		<div className="flow-popup">
			<div className="content">
				<h1>Scegli la portata</h1>
				{flows.map((flow, i) => (
					<button
						key={i}
						onClick={() => {
							add_product({ ...data, flow: flow });
						}}
					>
						Portata {flow}
					</button>
				))}
			</div>
		</div>
	);
}

export default FlowPopUp;
