import { useContext, useEffect, useState } from "react";
import { TableContext, SettingsContext } from "../App";
import Api from "./api/api";
import "../css/Limits.css";

function Limits() {
	const { timerActive, monoOrder } = useContext(SettingsContext);
	const { table } = useContext(TableContext);
	const [limits, setLimits] = useState([]);
	const [expanded, setExpanded] = useState(false);

	let limit_timeout;

	useEffect(() => {
		load_limits();
		return () => {
			console.log("clear limit_timeout");
			clearTimeout(limit_timeout);
		};
	}, []);

	const load_limits = async () => {
		console.log("load_limits");
		if (table != "") {
			const response = await Api.post("/self_cart/limits/" + table.id + "/", {
				mono_order: monoOrder,
			});
			if (response.success === 0) alert(response.error);
			setLimits(response.groups);
			limit_timeout = setTimeout(load_limits, 15000);
		}
	};

	return (
		<>
			{limits.length > 0 && (
				<div className={"limits" + (expanded ? " open" : "")}>
					<div className="limits-container">
						<button onClick={() => setExpanded(!expanded)}>
							{expanded ? <>&#62;</> : <>&#60;</>}
						</button>
						<div className="content">
							<div className="container-coves">
								<div className="covers">
									<i className="fa fa-user" aria-hidden="true"></i>
									<b>{table.covers}</b>
								</div>
								{timerActive && (
									<div className="covers">
										<i className="fa fa-clock" aria-hidden="true"></i>
										<b>{timerActive}</b>
									</div>
								)}
							</div>
							<div className="list-group">
								{limits &&
									limits.map((group) => (
										<div key={group.id}>
											<b>{group.used}/{group.order_limit}</b> {group.name}
										</div>
									))}
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default Limits;
