import { useContext, useEffect, useState } from "react";
import { SettingsContext } from "../App";

function Label({ number }) {
	const { labels, lang } = useContext(SettingsContext);
	const [label, setLabel] = useState(null);

	useEffect(() => {
		if (labels) {
			let l = labels.filter((l) => {
				return l.id === number;
			});
			if (l.length > 0) {
				if (l[0][lang]) setLabel(l[0][lang]);
				else setLabel(number);
			}
		}
		// eslint-disable-next-line
	}, [labels, number, lang]);

	return label;
}

export default Label;
