import { useContext, useEffect, useState } from "react";
import Label from "../Label";
import { AlertContext, CartContext, SettingsContext, TableContext } from "../../App";
import api from "../api/api";

export default function ProductNotesPopup({ itemId, onClose }) {
	const { setLoading, monoOrder } = useContext(SettingsContext);
	const { table } = useContext(TableContext);
	const { cart } = useContext(CartContext);
	const { setAlertData } = useContext(AlertContext);
	const [notes, setNotes] = useState("");

	useEffect(() => {
		const product = cart.products.find((p) => p.item_id == itemId);
		if (product) setNotes(product.notes);
	}, [itemId]);

	const save = async () => {
		setLoading(true);
		const response = await api.post("/self_cart/edit_product_notes/" + table.id + "/", {
			item_id: itemId,
			notes: notes,
			mono_order: monoOrder,
		});
		if (response.success === 0) {
			setLoading(false);
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
		} else {
			onClose();
		}
	};

	return (
		<div className="payment-popup">
			<div className="content">
				<div className="title">
					<Label number={143} />
				</div>
				<textarea value={notes} onChange={(e) => setNotes(e.target.value)}></textarea>
				<button
					className="button primary"
					onClick={() => {
						save();
					}}
				>
					<Label number={5} />
				</button>
				<button className="button" onClick={onClose}>
					<Label number={6} />
				</button>
			</div>
		</div>
	);
}
