import { useContext, useEffect, useState } from "react";
import { SettingsContext, ShopContext, TableContext } from "../App";
import Api from "./api/api";
import "../css/Pin.css";
import Label from "./Label";

function Lists() {
	const { settings, editTable, setLoading } = useContext(SettingsContext);
	const { shop, brand } = useContext(ShopContext);
	const [lists, setLists] = useState([]);
	const { table } = useContext(TableContext);

	useEffect(() => {
		if (shop) loadLists();
	}, [shop]);

	const loadLists = async () => {
		setLoading(true);
		const response = await Api.post("/self_cart/lists/", {
			shop_id: shop.id,
		});
		setLoading(false);
		if (response.success === 0) alert(response.error);
		setLists(response.rows);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const data = new FormData(e.target);
		const request = Object.fromEntries(data.entries());
		editTable({ list_id: request.list_id });
	};

	return (
		<div id="pin">
			<div className="logo">
				<img src={settings.selfordering.logo_home} alt="Logo" />
			</div>
			<div
				className="form"
				style={{
					backgroundColor: settings.selfordering.color1,
					color: settings.selfordering.color2,
				}}
			>
				<form className="content" onSubmit={handleSubmit}>
					<h2>
						<Label number={1} />
					</h2>
					<p>
						<Label number={2} />
					</p>
					<div className="form-line">
						<div
							className="label"
							style={{
								backgroundColor: settings.selfordering.color2,
								color: settings.selfordering.color1,
							}}
						>
							<Label number={3} />
						</div>
						<select name="list_id" required>
							<option value=""></option>
							{lists.map((el) => (
								<option key={el.id} value={el.id}>
									{el.name}
								</option>
							))}
						</select>
					</div>
					<button
						style={{
							backgroundColor: settings.selfordering.color3,
							color: settings.selfordering.color4,
						}}
					>
						Prosegui
					</button>
				</form>
			</div>
		</div>
	);
}

export default Lists;
