import React, { useContext } from "react";
import { CategoriesContext, SettingsContext } from "../App";
import "../css/Categories.css";

function Categories() {
	const { settings, setCategory, course } = useContext(SettingsContext);
	const { categories } = useContext(CategoriesContext);

	return (
		<>
			{settings.selfordering.list_orizzontal_categories == 1 ? (
				<>
					{settings.selfordering.categories_menu_type == 2 ? (
						<div className="courses">
							{categories &&
								categories.map((cr) =>
									cr.categories.map((c, i) => (
										<button
											key={i}
											onClick={() => setCategory(c)}
											className="course"
											style={{
												backgroundColor: settings.selfordering.color1,
												color: settings.selfordering.color2,
											}}
										>
											{c.name}
										</button>
									))
								)}
						</div>
					) : (
						<div className="courses">
							{course &&
								course.categories.map((c, i) => (
									<button
										key={i}
										onClick={() => setCategory(c)}
										className="course"
										style={{
											backgroundColor: settings.selfordering.color1,
											color: settings.selfordering.color2,
										}}
									>
										{c.name}
									</button>
								))}
						</div>
					)}
				</>
			) : (
				<>
					{settings.selfordering.categories_menu_type == 2 ? (
						<div className="row">
							{categories &&
								categories.map((cr) =>
									cr.categories.map((c, i) => (
										<div key={i} className="col-6 col-sm-4 col-lg-3">
											<button
												onClick={() => setCategory(c)}
												className="category"
											>
												{c.img && (
													<div className="img">
														{c.img && <img src={c.img} />}
													</div>
												)}
												<p>{c.name}</p>
											</button>
										</div>
									))
								)}
						</div>
					) : (
						<div className="row">
							{course &&
								course.categories.map((c, i) => (
									<div key={i} className="col-6 col-sm-4 col-lg-3">
										<button onClick={() => setCategory(c)} className="category">
											{c.img && (
												<div className="img">
													{c.img && <img src={c.img} />}
												</div>
											)}
											<p>{c.name}</p>
										</button>
									</div>
								))}
						</div>
					)}
				</>
			)}
		</>
	);
}

export default Categories;
