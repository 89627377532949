import React, { useContext, useEffect, useState } from "react";
import Api from "./api/api";
import { SettingsContext } from "../App";
import "../css/Legal.css";
import CloseButton from "./CloseButton";

function PagePopup({ id }) {
	const { settings, lang, setPagePopup } = useContext(SettingsContext);
	const [content, setContent] = useState(false);

	useEffect(() => {
		load_page();
	}, []);

	const load_page = async () => {
		console.log("load_page");
		const response = await Api.post("/pages/get/" + id + "/", {
			lang: lang,
		});
		console.log(response);
		setContent(response.data);
	};

	return (
		<>
			{content && (
				<div className="popup legal-popup">
					<div className="content">
						<div className="popup-header">
							<CloseButton onClick={() => setPagePopup(false)} />
							<h3 className="mb-0">{content.title}</h3>
						</div>
						<div className="popup-scroll">
							<div className="popup-content">
								<div
									className="small"
									dangerouslySetInnerHTML={{ __html: content.text }}
								></div>
							</div>
						</div>
					</div>
				</div>
			)}
		</>
	);
}

export default PagePopup;
