import { useContext } from "react";
import { SettingsContext } from "../App";
import "../css/MonoOrderConfirm.css";
import Label from "./Label";

function QrOrderConfirm({ order, onClose }) {
	const { settings, setOrdersVisible } = useContext(SettingsContext);

	return (
		<div
			className="mono-order-confirm test"
			style={{
				backgroundColor: settings.selfordering.color1,
			}}
		>
			<div className="content">
				<h4>
					<Label number={145} />
				</h4>
				<span>
					<Label number={146} />
				</span>
				<h1>{order.number}</h1>
				<img
					src={
						"https://api.yellgo.cloud/class/barcode.php?f=png&s=qr&p=0&w=500&h=500&d=" +
						order.id
					}
				/>
				<h4>ID: {order.id}</h4>
				<button
					onClick={onClose}
					className="button"
					style={{
						backgroundColor: settings.selfordering.color1,
						color: settings.selfordering.color2,
					}}
				>
					<Label number={147} />
				</button>
				<button
					className="button"
					onClick={() => {
						onClose();
						setOrdersVisible(true);
					}}
					style={{
						backgroundColor: settings.selfordering.color1,
						color: settings.selfordering.color2,
						marginTop: "10px",
					}}
				>
					<Label number={121} />
				</button>
			</div>
		</div>
	);
}

export default QrOrderConfirm;
