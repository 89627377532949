import Label from "./Label";

function Badges({ product }) {
	return (
		<div className="badge-container">
			{product.discounted == 1 && (
				<div className="label-promo">
					<Label number={110} />
				</div>
			)}
			{product.new == 1 && (
				<div className="label-new">
					<Label number={7} />
				</div>
			)}
			{product.best_sell == 1 && (
				<div className="label-sell">
					<Label number={8} />
				</div>
			)}
			{product.vegan == 1 && (
				<div className="label-vegan">
					<Label number={9} />
				</div>
			)}
			{product.gluten_free == 1 && (
				<div className="label-gluten">
					<Label number={10} />
				</div>
			)}
			{product.disabled == 1 && (
				<div className="label-available">
					<Label number={11} />
				</div>
			)}
			{product.custom_label == 1 && (
				<div className="label-custom">
					<Label number={12} />
				</div>
			)}
		</div>
	);
}

export default Badges;
