import { useContext, useEffect, useState } from "react";
import {
	SettingsContext,
	CartContext,
	TableContext,
	AlertContext,
	ShopContext,
	UserContext,
} from "../App";
import currencyFormat from "./functions/functions";
import Api from "./api/api";
import "../css/Cart.css";
import PaymentPopup from "./PaymentPopup";
import CloseButton from "./CloseButton";
import Products from "./cart/Products";
import Label from "./Label";
import NotesPopup from "./cart/NotesPopup";
import ProductNotesPopup from "./cart/ProductNotesPopup";

function Cart({ setCartVisible }) {
	const {
		settings,
		legal,
		setIsPaymentUser,
		setLegalPupop,
		setPagePopup,
		accessType,
		credit,
		setCredit,
		userID,
		getRelatedProducts,
		timerActive,
		monoOrder,
		handleCheckout,
		showPaymentPopup,
		setShowPaymentPopup,
		checkout,
		closeCart,
		marketing,
		setMarketing,
		newsletter,
		setNewsletter,
		setLoading,
		productNotesPopup,
		setProductNotesPopup,
	} = useContext(SettingsContext);
	const { shop, brand } = useContext(ShopContext);
	const { table } = useContext(TableContext);
	const { cart, updateQty } = useContext(CartContext);
	const { setAlertData } = useContext(AlertContext);
	const { user } = useContext(UserContext);
	const [notesPopup, setNotesPopup] = useState(false);

	useEffect(() => {
		if (settings.menu_api?.enable_related_products_on_checkout == 1) {
			getRelatedProducts(getCartProductsIds());
		}
	}, []);

	const countProducts = () => {
		var count = 0;
		for (var i = 0; i < cart.products.length; i++) count += cart.products[i].qty;
		return count;
	};

	const addDiscountFidelity = async (e) => {
		setLoading(true);
		const response = await Api.post("/self_cart/add_discount_fidelity/" + table.id + "/", {
			mono_order: monoOrder,
			discount_id: e.target.value,
		});
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
	};

	const deleteDiscountFidelity = async () => {
		setLoading(true);
		const response = await Api.post("/self_cart/delete_discount_fidelity/" + table.id + "/", {
			mono_order: monoOrder,
		});
		setLoading(false);
		if (response.success == 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
	};

	const addDiscountCode = async () => {
		setLoading(true);
		const response = await Api.post("/self_cart/add_discount_code/" + table.id + "/", {
			mono_order: monoOrder,
			shop_id: shop.id,
			code: document.getElementById("code").value,
			customer_id: user.id,
		});
		setLoading(false);
		if (response.success === 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
	};

	const deleteDiscountCode = async (item_id) => {
		setLoading(true);
		const response = await Api.post("/self_cart/delete_discount_code/" + table.id + "/", {
			item_id: item_id,
		});
		setLoading(false);
		if (response.success === 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
	};

	const saveNotes = async (request) => {
		console.log("saveNotes");
		request.mono_order = monoOrder;
		setLoading(true);
		const response = await Api.post("/self_cart/save_notes/" + table.id + "/", request);
		setLoading(false);
		if (response.success === 0)
			setAlertData({
				message: response.error,
				confirmMessage: <Label number={31} />,
			});
	};

	const anchorTo = (id) => {
		document.getElementById(id)?.scrollIntoView({ behavior: "smooth" });
	};

	const getCartProductsIds = () => {
		let res = [];
		for (const product of cart.products) {
			res.push(product.product_id);
		}
		return res;
	};

	return (
		<>
			<div className="popup">
				{cart && (
					<div className="content cart">
						<div className="cart-header">
							<CloseButton onClick={() => setCartVisible(false)} />
							<h3 className="mb-0">
								<Label number={19} />
							</h3>
							<p className="mb-0 small">
								<Label number={73} />
							</p>

							{settings.selfordering.alert_limit != "" &&
								countProducts() >= parseInt(settings.selfordering.alert_limit) && (
									<div className="alert p-2 alert-warning mt-1 mb-0 small">
										<Label number={109} />
									</div>
								)}
							<div className="header-buttons">
								<button onClick={() => anchorTo("promo")}>
									<Label number={119} />
								</button>
								{user &&
									settings?.credit?.active == 1 &&
									cart.customer?.credit > 0 && (
										<button
											className="cart-button"
											onClick={() => anchorTo("credit")}
										>
											<Label number={90} />
										</button>
									)}
								{settings.selfordering.hide_order_notes != 1 && (
									<button onClick={() => setNotesPopup(true)}>
										<Label number={4} />
									</button>
								)}
							</div>
						</div>

						<div className="cart-scroll">
							<div className="cart-products">
								<Products
									products={cart.products}
									timerActive={timerActive}
									updateQty={updateQty}
									mode={1}
								/>
								{cart.discounts_fidelity &&
									cart.discounts_fidelity.map((discount, i) => (
										<div key={"dis-" + i} className="cart-row">
											<div className="name">
												<button
													onClick={deleteDiscountFidelity}
													className="me-2 delete-button"
												>
													&times;
												</button>
												{discount.name}
											</div>
											<div className="prices">
												<div className="price">
													-
													{currencyFormat(
														parseFloat(discount.price),
														false,
														shop.currency
													)}
												</div>
											</div>
										</div>
									))}
								{cart.discounts_code &&
									cart.discounts_code.map((discount, i) => (
										<div key={"disc-" + i} className="cart-row">
											<div className="name">
												{discount.removable == 1 && (
													<button
														onClick={() =>
															deleteDiscountCode(discount.item_id)
														}
														className="me-3 delete-button"
													>
														&times;
													</button>
												)}
												{discount.name}
											</div>
											<div className="prices">
												<div className="price">
													-
													{currencyFormat(
														parseFloat(discount.price),
														false,
														shop.currency
													)}
												</div>
											</div>
										</div>
									))}
								{credit && cart.credit_total > 0 && (
									<div className="cart-row">
										<div className="name">Credito prepagato</div>
										<div className="prices">
											<div className="price">
												-
												{currencyFormat(
													parseFloat(cart.credit_total),
													false,
													shop.currency
												)}
											</div>
										</div>
									</div>
								)}
								<div className="total-row">
									<Label number={25} />:{" "}
									<span>{currencyFormat(cart.total, false, shop.currency)}</span>
								</div>
							</div>
							{user && (
								<div className="promo-box" id="promo">
									<label>
										<div className="title">
											<Label number={14} />:
										</div>
									</label>
									<div className="button-groups">
										<input id="code" type="text" className="form-control" />
										<button onClick={addDiscountCode}>
											<Label number={15} />
										</button>
									</div>
								</div>
							)}
							{settings.fidelity?.active == 1 &&
								user &&
								cart.availables_discounts_fidelity.length > 0 &&
								cart.discounts_fidelity.length == 0 && (
									<div className="promo-box">
										<label>
											<div className="title">
												<Label number={84} />:
											</div>
											<div className="small">
												<Label number={85} />: {cart.customer.points}{" "}
											</div>
										</label>
										<div>
											<select
												className="form-select"
												onChange={addDiscountFidelity}
											>
												<option key={-1} value=""></option>
												{cart.availables_discounts_fidelity.map(
													(discount, i) => (
														<option key={i} value={discount.id}>
															{discount.name}
														</option>
													)
												)}
											</select>
											<div className="small">
												<Label number={86} />: {cart.fidelity_points}{" "}
												<Label number={87} />
											</div>
										</div>
									</div>
								)}
							{user && settings?.credit?.active == 1 && cart.customer?.credit > 0 && (
								<div className="promo-box" id="credit">
									<label>
										<div className="title">
											<Label number={88} />:
										</div>
										<div className="small">
											<Label number={89} />:{" "}
											{currencyFormat(
												cart.customer.credit,
												false,
												shop.currency
											)}
										</div>
									</label>
									<div>
										{credit ? (
											<button
												className="cart-button"
												onClick={() => setCredit(false)}
											>
												<Label number={91} />
											</button>
										) : (
											<button
												className="cart-button"
												onClick={() => setCredit(true)}
											>
												<Label number={90} />
											</button>
										)}
									</div>
								</div>
							)}
						</div>
						<div className="totals">
							<div className="disclaimers">
								{user &&
									legal &&
									Object.keys(legal).map((id, i) => {
										if (id == "newsletter") {
											if (cart.customer && cart.customer[id] == 0) {
												return (
													<div className="form-group form-check" key={i}>
														<input
															type="checkbox"
															className="form-check-input"
															id={id}
															name={id}
															onChange={() =>
																setNewsletter(
																	newsletter == 1 ? 0 : 1
																)
															}
															checked={newsletter}
														/>
														<label
															className="form-check-label small"
															htmlFor={id}
														>
															{legal[id].label_text}
															<a
																href="#"
																onClick={() =>
																	setLegalPupop(legal[id])
																}
															>
																{legal[id].label_linked}
															</a>
														</label>
													</div>
												);
											}
										}
										if (id == "marketing") {
											if (cart.customer && cart.customer[id] == 0) {
												return (
													<div className="form-group form-check" key={i}>
														<input
															type="checkbox"
															className="form-check-input"
															id={id}
															name={id}
															onChange={() =>
																setMarketing(marketing == 1 ? 0 : 1)
															}
															checked={marketing}
														/>
														<label
															className="form-check-label small"
															htmlFor={id}
														>
															{legal[id].label_text}
															<a
																href="#"
																onClick={() =>
																	setLegalPupop(legal[id])
																}
															>
																{legal[id].label_linked}
															</a>
														</label>
													</div>
												);
											}
										}
									})}
								{legal &&
									Object.keys(legal).map((id, i) => {
										if (id == "privacy") {
											return (
												<div className="" key={i}>
													{legal[id].label_text}
													{settings?.orders?.privacy_page_id == "" ? (
														<a
															href="#"
															onClick={() => setLegalPupop(legal[id])}
														>
															{legal[id].label_linked}
														</a>
													) : (
														<a
															href="#"
															onClick={() =>
																setPagePopup(
																	settings.orders.privacy_page_id
																)
															}
														>
															{legal[id].label_linked}
														</a>
													)}
													{legal[id].label_text_after}
												</div>
											);
										}
									})}
								<div className="">
									<Label number={106} />{" "}
									<a
										href="#"
										onClick={() => setPagePopup(settings.orders.tos_page_id)}
									>
										<Label number={107} />
									</a>
								</div>
							</div>
							<div className="buttons">
								<button onClick={() => setCartVisible(false)}>
									<Label number={6} />
								</button>
								{timerActive &&
								settings.selfordering?.disable_timer_for_priced != 1 ? (
									<button className="button-primary">{timerActive}</button>
								) : (
									<>
										{cart.products.length > 0 ? (
											<button
												className="button-primary"
												onClick={() => handleCheckout()}
											>
												<Label number={21} />
											</button>
										) : (
											<button className="button-primary">
												<Label number={21} />
											</button>
										)}
									</>
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			{notesPopup && (
				<NotesPopup
					value={cart.notes ? cart.notes[userID] : ""}
					onChange={(notes) => saveNotes({ user_id: userID, notes: notes })}
					onClose={() => setNotesPopup(false)}
				/>
			)}
			{productNotesPopup !== false && (
				<ProductNotesPopup
					itemId={productNotesPopup}
					onClose={() => setProductNotesPopup(false)}
				/>
			)}
			{showPaymentPopup && (
				<PaymentPopup
					showPaymentPopup={showPaymentPopup}
					setShowPaymentPopup={setShowPaymentPopup}
					onPayment={checkout}
					closeCart={closeCart}
					checkoutData={{
						shop_id: shop.id,
						mono_order: monoOrder,
						customer_id: user.id,
						brand_id: brand ? brand.id : null,
						user_id: userID,
						timer_active: timerActive ? true : false,
						self_mode: accessType,
						credit: credit,
						customer: {
							id: user.id,
							name: user.name,
							surname: user.surname,
							email: user.email,
							mobile: user.mobile,
						},
					}}
				/>
			)}
		</>
	);
}

export default Cart;
