import { useContext } from "react";
import { SettingsContext, ShopContext, UserContext } from "../App";
import "../css/PaymentPopup.css";
import Nexi from "./paymentsButtons/Nexi";
import Paypal from "./paymentsButtons/Paypal";
import Stripe from "./paymentsButtons/Stripe";
import Vivawallet from "./paymentsButtons/Vivawallet";
import Satispay from "./paymentsButtons/Satispay";

function PaymentPopup({ setShowPaymentPopup, onPayment, closeCart, checkoutData }) {
	const { settings, setSettings } = useContext(SettingsContext);
	const { shop, setShop } = useContext(ShopContext);
	const { user } = useContext(UserContext);

	return (
		<div className="payment-popup">
			<div className="content">
				<div className="title">Pagamento</div>
				{shop.payment_methods[1].active_selfordering == 1 && (
					<button
						className="button"
						onClick={() => onPayment(1)}
						style={{
							backgroundColor: settings.selfordering.color1,
							color: settings.selfordering.color2,
						}}
					>
						{shop.payment_methods[1].label}
					</button>
				)}
				{shop.payment_methods[2].active_selfordering == 1 && (
					<button
						className="button"
						onClick={() => onPayment(2)}
						style={{
							backgroundColor: settings.selfordering.color1,
							color: settings.selfordering.color2,
						}}
					>
						{shop.payment_methods[2].label}
					</button>
				)}
				{shop.payment_methods[3].active_selfordering == 1 && (
					<div className="paypal">
						<Paypal onPayment={onPayment} />
					</div>
				)}
				{shop.payment_methods[8].active_selfordering == 1 && (
					<div className="mb-3">
						<Satispay />
					</div>
				)}
				{shop.payment_methods[9].active_selfordering == 1 && (
					<div className="mb-3">
						<Stripe onPayment={onPayment} />
					</div>
				)}
				{shop.payment_methods[11].active_selfordering == 1 && (
					<Nexi closeCart={closeCart} checkoutData={checkoutData} />
				)}
				{shop.payment_methods[12].active_selfordering == 1 && user && (
					<Vivawallet onPayment={onPayment} />
				)}
				<button className="button" onClick={() => setShowPaymentPopup(false)}>
					Annulla
				</button>
			</div>
		</div>
	);
}

export default PaymentPopup;
